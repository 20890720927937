<template>
  <div>
    <div v-if="list.length > 0">
      <div
        class="bg-body rounded-3 shadow-200 mb-3"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="flex-between-center p-3">
          <div class="flex-start-center">
            <div class="ratio ratio-1x1 w-px-30 me-2 flex-shrink-0">
              <img
                :src="CheckUserImage(item.profile_image)"
                alt="avatar"
                class="avatar rounded-circle w-100 object-fit-cover"
              />
            </div>
            <span class="fw-bold">{{ item.user_name }}</span>
          </div>
          <a
            :href="`tel:${item.user_phone}`"
            class="small flex-start-center rounded-pill bg-gray-relative-200 py-1 px-2 flex-shrink-0"
          >
            <i
              class="fas fa-mobile-alt text-gray-relative-600 ms-1 me-2 small"
            ></i>
            <span class="me-1 small">{{ item.user_phone }}</span>
            <i
              class="fas fa-chevron-right small ms-1 text-gray-relative-500"
            ></i>
          </a>
        </div>
        <hr class="cutting_line border-gray-relative-600 h-0 my-0 mx-3" />
        <div class="p-3">
          <div class="flex-between-center">
            <div class="flex-start-center small mb-2">
              <i
                class="fa fa-map-marker text-gray-relative-400 me-2 w-px-18"
              ></i>
              <span>{{ item.location_name }}</span>
            </div>
            <p class="small text-end mb-2 text-muted">
              {{ addDay(item.update_time) }}
            </p>
          </div>
          <div class="flex-start-center small mb-2" v-if="item.type != 'D'">
            <i class="fal fa-calendar text-gray-relative-400 me-2 w-px-18"></i>
            <span
              >{{ item.date }} ({{ item.start_time }} ~
              {{ item.end_time }})</span
            >
          </div>

          <div class="flex-start-center small" v-if="item.confirm_time != ''">
            <i class="fal fa-clock text-gray-relative-400 me-2 w-px-18"></i>
            <span>{{ addDay(item.date) }} ({{ item.confirm_time }})</span>
          </div>

          <div class="mt-3">
            <div class="d-flex justify-content-between">
              <div>
                <div
                  class="small mb-2 mt-2 badge rounded-pill"
                  :class="CheckStatusColor(item.status)"
                >
                  <i
                    class="fas me-2 w-px-18 small"
                    :class="{
                      'fa-spinner': item.status == 'H',
                      'fa-check': item.status == 'Y',
                      'fa-times': item.status == 'C',
                      'fa-trash': item.status == 'CP',
                      'fa-calendar': item.status == 'P',
                    }"
                  ></i>
                  <span>{{ CheckStatus(item.status) }}</span>
                </div>
                <div
                  class="small mb-2 mt-2 ms-2 badge rounded-pill badge_success_light"
                  v-if="item.type == 'D'"
                >
                  <i class="fas me-2 w-px-18 small fa-check"></i>
                  <span>직접예약</span>
                </div>
              </div>
              <div>
                <div
                  class="small mb-2 mt-2 ms-2 badge rounded-pill badge_warning_light"
                  @click="GotoReservationDetail(item.code)"
                >
                  <i class="fas me-2 w-px-18 small fa-search"></i>
                  <span>상세정보</span>
                </div>
                <!-- <div
                  class="small mb-2 mt-2 ms-2 badge rounded-pill badge_danger_light"
                  v-if="item.status == 'CP'"
                  @click="ConfirmCancel(item.code)"
                >
                  <i class="fas me-2 w-px-18 small fa-info"></i>
                  <span>취소승인</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="border-top p-3 border-gray-relative-200"
          v-if="item.status == 'H'"
        >
          <ul class="d-flex">
            <li
              class="rounded-3 p-2 text-center bg-gray-relative-200 flex-grow-1 me-1"
              @click="Refuse(item)"
            >
              {{ CheckStatus('C') }}
            </li>
            <li
              class="rounded-3 p-2 text-center bg-main flex-grow-1 text-white"
              @click="Approval(item)"
              v-if="item.type != 'D'"
            >
              {{ CheckStatus('P') }}
            </li>
            <li
              class="rounded-3 p-2 text-center bg-main flex-grow-1 text-white"
              @click="ApprovalDirect(item)"
              v-if="item.type == 'D'"
            >
              {{ CheckStatus('P') }}
            </li>
          </ul>
        </div>
        <div
          class="border-top p-3 border-gray-relative-200"
          v-if="item.status == 'CP'"
        >
          <div class="d-flex">
            <li
              class="rounded-3 p-2 text-center bg-gray-relative-200 flex-grow-1 me-1"
              @click="RefusePayment(item)"
            >
              취소거절
            </li>
            <li
              class="rounded-3 p-2 text-center bg-main flex-grow-1 text-white"
              @click="GotoPaymentReceipt(item.code)"
              v-if="item.type == 'D'"
            >
              취소승인
            </li>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty_data">
      <p class="text-center py-5">예약이 없습니다.</p>
    </div>
  </div>
</template>

<script>
import { format } from '@/mixins';
const CryptoJS = require('crypto-js');

export default {
  mixins: [format],
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  methods: {
    GotoReservationDetail(code) {
      this.$router.push({ path: `/reservation/detail/${code}` });
    },
    GotoPaymentReceipt(code) {
      this.$router.push({ path: `/payment/receipt/${code}` });
    },
    CheckStatusColor(status) {
      if (status == 'H') {
        return 'badge_warning_light';
      } else if (status == 'Y') {
        return 'badge_success_light';
      } else if (status == 'C') {
        return 'badge_danger_light';
      } else if (status == 'P') {
        return 'badge_warning_light';
      } else if (status == 'CP') {
        return 'badge_danger_light';
      }
    },
    CheckStatus(status) {
      if (status == 'Y') {
        return '예약완료';
      } else if (status == 'H') {
        return '결제대기';
      } else if (status == 'P') {
        return '결제요청';
      } else if (status == 'C') {
        return '예약취소';
      } else if (status == 'CP') {
        return '취소요청';
      }
    },
    CheckUserImage(src) {
      if (src == '') {
        return '/assets/image/profile_default.svg';
      } else {
        return src;
      }
    },
    Refuse(item) {
      this.$confirm(
        `매장명 : ${item.location_name}<br>예약일 : ${item.date}<br>예약을 거절하시겠습니까?`
      ).then((result) => {
        if (result.isOk) {
          this.CancelReservation(item);
        }
      });
    },

    Approval(item) {
      this.$confirm(
        `매장명 : ${item.location_name}<br>'${item.date} (${item.start_time} ~ ${item.end_time})'<br>결제 요청을 하시겠습니까?`
      ).then((result) => {
        if (result.isOk) {
          this.$router.push(`/reservation/payment/${item.code}`);
        }
      });
    },
    ApprovalDirect(item) {
      this.$confirm(
        `매장명 : ${item.location_name}<br>'${item.date} (${item.confirm_time})'<br>결제 요청을 하시겠습니까?`
      ).then((result) => {
        if (result.isOk) {
          this.$router.push(`/reservation/payment/${item.code}`);
        }
      });
    },

    TimeFormmat(time_str) {
      if (time_str == 'A') {
        return '오전';
      } else {
        return '오후';
      }
    },
    DayFormat(day_str) {
      const days = ['일', '월', '화', '수', '목', '금', '토'];
      const date = new Date(day_str);
      return days[date.getDay()];
    },
    CancelReservation(item) {
      const code = item.code;
      const body = { code };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http
        .post('/shop/reservation/CancelReservation', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              this.$alert('예약을 취소하였습니다.').then(() => {
                this.$router.go();
              });
            }
          }
        });
    },
    CancelReservationReject(item) {
      const code = item.code;
      const body = { code };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.$http
        .post('/shop/reservation/CancelReservationReject', { req })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code == '200') {
              this.$alert('취소 요청이 거절되었습니다.').then(() => {
                this.$router.go();
              });
            }
          }
        });
    },
    RefusePayment(item) {
      this.$confirm(
        `매장명 : ${item.location_name}<br>예약일 : ${item.date}<br>취소 요청을 거절하시겠습니까?`
      ).then((result) => {
        if (result.isOk) {
          this.CancelReservationReject(item);
        }
      });
    },
  },
};
</script>

<style></style>
